<template>
  <NewModelLayout>
    <template #title>
      <h1 class="text-2xl">
        {{ route.title }}
      </h1>
    </template>
    <template #form>
      <vue-form
        :form="form"
        :errors.sync="error.errors"
        ref="form"
        class="bg-gray-100 shadow rounded-sm"
      >
        <div class="bg-white px-6 py-4">
          <div class="flex flex-wrap w-row-offset-sm -mx-2">
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.value.title"
                :errors="error.errors.title"
                name="title"
                prop="title"
                label="Title"
                description="The title of the page."
                required
              />
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.value.slug"
                :errors="error.errors.slug"
                name="slug"
                prop="slug"
                label="Slug"
                description="The slug for this page. Auto-generated when the name is filled."
                required
              />
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.key"
                :errors="error.errors.key"
                name="key"
                prop="key"
                label="Metadata Key"
                description="The metadata key for this page. Auto-generated when the name is filled."
                required
              />
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center w-full px-4 py-4">
          <vue-button
            text
            error
            @click="resetForm"
          >
            Reset
          </vue-button>
          <vue-button
            :icon="['fas', 'save']"
            type="submit"
            success
            @click="submitModel"
          >
            Add {{ route.singular }}
          </vue-button>
        </div>
      </vue-form>
    </template>
  </NewModelLayout>
</template>
<script>
import { cloneDeep } from 'lodash'
import NewModelLayout from '@/components/layouts/NewModelLayout'
import HasSlug from '@/mixins/HasSlug'

export default {
  name: 'ProductsNew',

  components: {
    NewModelLayout
  },

  mixins: [
    HasSlug
  ],

  data () {
    return {
      loading: true,
      form: {},
      defaults: {
        key: '',
        type: 'page',
        protected: false,
        value: {
          title: '',
          slug: '',
          layout: {
            cols: ['1fr'],
            rows: [],
            autoCols: '1fr',
            autoRows: '1fr'
          },
          seo: {
            keyphrase: '',
            title: '',
            description: ''
          }
        }
      },
      keyPrepend: 'page_',
      route: {
        title: 'Add New Page',
        singular: 'Page',
        plural: 'Pages',
        apiEndpoint: 'metadata',
        routeNext: 'pages.single'
      },
      error: {
        errors: {}
      }
    }
  },

  watch: {
    'form.value.title': function (val) {
      this.form.key = `${this.keyPrepend}${this.getSlug(val, '_')}`
      this.form.value.slug = this.getSlug(val, '-', '/')
    }
  },

  mounted () {
    this.initForm()
  },

  methods: {
    initForm () {
      this.form = cloneDeep(this.defaults)
    },

    resetForm () {
      this.initForm()
    },

    submitModel () {
      this.$refs.form.validate()
      this.$api.post(this.route.apiEndpoint, this.form)
        .then((res) => {
          this.$router.push({
            name: this.route.routeNext,
            params: {
              model: res.data.id
            }
          })
        })
        .catch((err) => {
          this.error = err
        })
    }
  }
}
</script>
